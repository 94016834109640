import { ObjectState } from 'cvat-core-wrapper';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers';

export const useRelatedImagery = () => {
    const plugin = useSelector((state: CombinedState) => state.plugins.apis.job);
    const job = useSelector((state: CombinedState) => state.annotation.job.instance);
    const relatedFiles = useSelector((state: CombinedState) => state.annotation.player.frame.relatedFiles);
    const states = useSelector((state: CombinedState) => state.annotation.annotations.states as ObjectState[]);

    const pluginImageryCount = useMemo(() => {
        if (plugin) return plugin.getRelatedImageryCount(states);
    }, [plugin, states]);

    return useMemo(
        () => ({
            getContextImages: async (frameId: number): Promise<Record<string, ImageBitmap>> => {
                if (plugin && pluginImageryCount) {
                    return plugin.getRelatedImagery(states);
                }
                return job.frames.contextImage(frameId);
            },
            contextImageCount: pluginImageryCount ?? relatedFiles,
        }),
        [job.frames, plugin, pluginImageryCount, relatedFiles, states],
    );
};
