// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from 'react';
import { Dispatch, AnyAction } from 'redux';
import { useDispatch } from 'react-redux';

import { PluginsActionTypes, pluginActions } from 'actions/plugins-actions';
import { getCore, APIWrapperEnterOptions } from 'cvat-core-wrapper';
import { PluginInterfaces } from 'reducers';

const core = getCore();

export type ComponentBuilder = ({
    dispatch,
    REGISTER_ACTION,
    REMOVE_ACTION,
    core,
}: {
    dispatch: Dispatch<AnyAction>;
    REGISTER_ACTION: PluginsActionTypes.ADD_UI_COMPONENT;
    REMOVE_ACTION: PluginsActionTypes.REMOVE_UI_COMPONENT;
    core: any;
}) => {
    name: string;
    destructor: CallableFunction;
    globalStateDidUpdate?: CallableFunction;
    interfaces?: PluginInterfaces;
};

export type PluginEntryPoint = (componentBuilder: ComponentBuilder) => void;
export type { APIWrapperEnterOptions };

function PluginEntrypoint(): null {
    const dispatch = useDispatch();

    useEffect(() => {
        Object.defineProperty(window, 'cvatUI', {
            value: Object.freeze({
                registerComponent: (componentBuilder: ComponentBuilder) => {
                    const { name, destructor, globalStateDidUpdate, interfaces } = componentBuilder({
                        dispatch,
                        REGISTER_ACTION: PluginsActionTypes.ADD_UI_COMPONENT,
                        REMOVE_ACTION: PluginsActionTypes.REMOVE_UI_COMPONENT,
                        core,
                    });

                    dispatch(pluginActions.addPlugin(name, destructor, globalStateDidUpdate, interfaces));
                },
            }),
        });

        if (queuedPlugins) {
            queuedPlugins.forEach(doRegister);
            queuedPlugins = null;
        }
    }, []);

    return null;
}

export default React.memo(PluginEntrypoint);

export const registerPlugin = (builder: ComponentBuilder): void => {
    if (queuedPlugins) {
        queuedPlugins.push(builder);
    } else {
        doRegister(builder);
    }
};

let queuedPlugins: ComponentBuilder[] | null = [];

const doRegister = (builder: ComponentBuilder) => {
    if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
        (window as any as { cvatUI: { registerComponent: PluginEntryPoint } }).cvatUI.registerComponent(builder);
    }
};
